






























































































































































































































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mask } from 'vue-the-mask'
import axios from 'axios'
import snakeCaseKeys from 'snakecase-keys'
import camelCaseKeys from 'camelcase-keys'

import ValidatorConfigMixin from '@/mixins/validatorConfigMixin'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import NotifyOnFailed from '@/partials/forms/NotifyOnFailed.vue'
import RichTextEditor from '@/partials/forms/components/RichTextEditor.vue'
import TopicsComboBox from '@/partials/forms/TopicsComboBox.vue'
import FileUploader from '@/partials/forms/components/FileUploader.vue'
import AuthorsInput from '@/partials/forms/Events/components/AuthorsInput.vue'
import TopicsCheckbox from '@/partials/forms/Events/EventTopicDropdown.vue'

type Author = { name: string }

@Component({
  directives: { mask },
  components: {
    TopicsCheckbox,
    AuthorsInput,
    FileUploader,
    TopicsComboBox,
    RichTextEditor,
    PasswordConfirmField,
    NotifyOnFailed,
    ValidationProvider,
    ValidationObserver
  }
})
export default class EventStudyForm extends Mixins(ValidatorConfigMixin, FlashMessageMixin) {
  @Prop({ required: true, type: String }) title!: string
  @Prop({ required: true, type: String }) eventId!: string
  @Prop({ type: String }) studyId!: string
  @Prop({ type: String }) type!: 'create' | 'edit'
  termModalIsOpened = true

  windowWidth = window.innerWidth
  done = true
  attachment = ''
  form: Record<string, any> = {
    title: '',
    topics: [],
    abstract: '',
    body: '',
    attachment: '',
    eventId: '',
    authors: [],
    confirm: '',
    presentation: 'digital',
    errors: {
      title: '',
      abstract: '',
      body: '',
      attachment: '',
      eventId: '',
      authors: '',
      topics: '',
      presentation: '',
      confirm: ''
    }
  }

  topics: Array<Topic> = []

  presentations = [
    {
      name: 'digital',
      prettyName: 'E-paper'
    },
    {
      name: 'oral',
      prettyName: 'Oral'
    }
  ]

  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  }

  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  }

  onResize () {
    this.windowWidth = window.innerWidth
  }

  created () {
    this.form.eventId = this.eventId
    if (process.env.NODE_ENV === 'development' && this.type !== 'edit') {
      Object.assign(this.form, {
        title: 'Meu Trabalho ABC',
        abstract: 'Lorem Ipsum',
        body: `
          <h1>Título Teste</h1>
          <p>Esse é um paragrafo de teste</p>
          <ul>
            <li><strong>Lista 1</strong></li>
            <li>Lista 2</li>
            <li>Lista 3</li>
          </ul>
          <blockquote>Isso é uma citação</blockquote>
        `
      })
    }

    if (this.type === 'edit') {
      axios.get(`/event/${this.eventId}/study/${this.studyId}`)
        .then(response => {
          const study = camelCaseKeys(response.data.data, { deep: true })

          Object.keys(study).forEach((key: string) => {
            if (key !== 'attachment') {
              this.form[key] = key in this.form ? study[key] : this.form[key]
            } else {
              this.attachment = study[key]
            }
          })
          this.form.topics = study.topics.map((topic: Topic) => topic.id)
          this.topics = study.topics

          this.form.authors = this.form.authors.map((author: Author) => author.name)
        })
    }
  }

  resetErrors () {
    this.form.errors = {
      title: '',
      abstract: '',
      body: '',
      attachment: '',
      eventId: '',
      authors: '',
      topics: '',
      presentation: '',
      confirm: ''
    }
  }

  submit () {
    this.done = false
    const verb = this.type === 'edit' ? 'put' : 'post'
    const route = this.type === 'edit' ? `/event/${this.eventId}/study/${this.studyId}` : `/event/${this.eventId}/study`

    axios[verb](route, snakeCaseKeys(this.form))
      .then(() => {
        this.redirectOnSuccess(this.form.title)
      })
      .catch(err => this.handleSubmitError(err))
      .finally(() => { this.done = true })
  }

  redirectOnSuccess (studyTitle: string) {
    const successVerb = this.type === 'edit' ? 'editado' : 'cadastrado'

    const flashMessage = {
      message: `O Trabalho Científico ${studyTitle} foi ${successVerb} com sucesso.`,
      kind: 'success'
    }

    this.$router.push({ name: 'EventStudies', params: { id: this.eventId } }, () => this.setFlashMessage(flashMessage))
  }

  redirectOnRejectTerms () {
    this.termModalIsOpened = false

    const flashMessage: flashMessage = {
      message: 'Caso deseje criar um Trabalho Científico, é preciso aceitar os termos.',
      type: 'info'
    }

    this.$nextTick(() => {
      this.$router.push({ name: 'EventStudies', params: { id: this.eventId } }, () => this.setFlashMessage(flashMessage))
    })
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = camelCaseKeys(response.data.errors, { deep: true })
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }
  }
}
