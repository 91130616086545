

























import { Component, ModelSync, Prop, Vue } from 'vue-property-decorator'

@Component
export default class AuthorsInput extends Vue {
  @ModelSync('value', 'input', { type: Array }) authors!: Array<string>
  @Prop({ type: Number, default: 6 }) limit!: number
  form = {
    author: ''
  }

  submit () {
    if (!this.form.author) return
    this.authors.push(this.form.author)

    this.form.author = ''
  }

  remove (name: string) {
    this.authors = this.authors.filter(author => author !== name)
  }

  get reachedLimit () {
    return this.authors.length >= this.limit
  }
}
