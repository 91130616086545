























import { Component, Vue, ModelSync, Prop } from 'vue-property-decorator'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'

@Component
export default class EventTopicDropdown extends Vue {
  @ModelSync('value', 'input', { type: Array }) inputField!: Array<Topic>
  @Prop({ type: String, required: true }) eventId!: string
  @Prop({ type: String }) invalidMessage!: string
  @Prop({ type: Boolean }) disabled!: boolean
  topics = [] as any

  async mounted () {
    await axios.get(`/event/${this.eventId}/topic/dropdown`)
      .then((response) => {
        this.topics = camelCaseKeys(response.data.data)
      })
  }
}
