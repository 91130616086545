




























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import axios from 'axios'

type ComboOption = {
  value: string;
  name: string;
  label: string;
}
@Component
export default class TopicsComboBox extends Vue {
  @Prop() formTopics!: Array<Topic>
  @Prop({ required: true, type: String }) requestUrl!: string
  searchTopics: Array<Topic> = []
  comboOptions: Array<ComboOption> = []
  topics: Array<Topic> = []
  values = ''
  pristine = true

  @Watch('formTopics', { immediate: true })
  updateTopics () {
    if (this.formTopics) {
      this.topics = this.formTopics
    }
  }

  search ($e: Event) {
    const target = $e.target as HTMLInputElement

    this.pristine = true
    if (target.value.length > 2) {
      axios.get(this.requestUrl, {
        params: {
          search: target.value
        }
      })
        .then(response => {
          this.pristine = false
          this.searchTopics = response.data.data

          this.comboOptions = this.searchTopics.map((topic: Topic) => {
            return {
              value: topic.id,
              name: topic.title ? topic.title : topic.name,
              label: topic.title ? topic.title : topic.name
            }
          })

          this.filterComboOptions()
        })
    }
  }

  filterComboOptions () {
    if (this.topics) {
      const ids = this.topics.map((topic: Topic) => topic.id)
      this.comboOptions = this.comboOptions.filter((option: ComboOption) => !ids.includes(option.value))
    }
  }

  changed (id: string) {
    const selectedTopic = this.searchTopics.find((topic: Topic) => topic.id === id)

    if (selectedTopic) {
      this.topics.push(selectedTopic)
      this.$emit('input', this.topics.map(topic => topic.id))
      this.filterComboOptions()
    }
  }

  removeTopic (index: number) {
    this.topics.splice(index, 1)
    this.$emit('input', this.topics.map(topic => topic.id))
  }
}
